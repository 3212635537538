import React, { FC, ReactNode } from "react";
import classNames from "classnames";

import styles from "./Container.module.scss";

export interface IContainerProps {
  className?: string;
  children: ReactNode;
}

const Container: FC<IContainerProps> = (props) => {
  return (
    <div className={classNames(styles.container, props.className)}>
      {props.children}
    </div>
  );
};

Container.defaultProps = {};

export default Container;
