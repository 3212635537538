import { FaLinkedinIn, FaTwitter, FaYoutube } from "react-icons/fa";
import { IconType } from "react-icons/lib";

export interface SocialNetworkItem {
  name: string;
  picto: IconType;
  link: string;
}

const socialNetworks: { [key: string]: SocialNetworkItem } = {
  linkedin: {
    name: "Linkedin",
    picto: FaLinkedinIn,
    link: "https://www.linkedin.com/company/esant%C3%A9-occitanie/",
  },
  twitter: {
    name: "Twitter",
    picto: FaTwitter,
    link: "https://twitter.com/GIPesanteOcc",
  },
  youtube: {
    name: "Youtube",
    picto: FaYoutube,
    link: "https://www.youtube.com/channel/UCa7IHLayzb2XrcJoZ8LGybg",
  },
};

export default socialNetworks;
