import React, { FC } from "react";

import ApiSectionDownload from "../../models/api/sections/sectionDownload.model";
import Container from "../Container";
import Download from "../Download";
import ImageCustom from "../ImageCustom";
import SectionBase from "./SectionBase";
import styles from "./SectionDownload.module.scss";

export interface ISectionDownloadProps extends ApiSectionDownload {}

const SectionDownload: FC<ISectionDownloadProps> = (props) => {
  return (
    <SectionBase
      title={props.title}
      background="secondary"
      titlePosition="center"
      titleStyle="secondary"
      className={styles.section}
    >
      <div className={styles.decoration}>
        <ImageCustom
          src="/vector/download.svg"
          alt=""
          width={250}
          height={250}
        />
      </div>
      {props.items && (
        <Container className={styles.container}>
          {props.items.map((item) => (
            <Download key={item.id} {...item} className={styles.download} />
          ))}
        </Container>
      )}
    </SectionBase>
  );
};

SectionDownload.defaultProps = {};

export default SectionDownload;
