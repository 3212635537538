import React, { ElementType, FC, ReactNode } from "react";
import classNames from "classnames";

import styles from "./Typo.module.scss";

interface ITypoProps {
  variant?:
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "body"
    | "button"
    | "socials"
    | "download"
    | "chapter"
    | "cta"
    | "headline";
  component?: ElementType;
  color?: "primary" | "secondary" | "light" | "dark";
  className?: string;
  children: ReactNode;
}

const Typo: FC<ITypoProps> = (props) => {
  return props.component ? (
    <props.component
      className={classNames(
        styles.typo,
        props.variant && styles[props.variant],
        styles[`color_${props.color}`],
        props.className
      )}
    >
      {props.children}
    </props.component>
  ) : (
    <></>
  );
};

Typo.defaultProps = { variant: "body", component: "span" };

export default Typo;
