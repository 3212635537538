import React, { FC, useEffect, useState } from "react";
import { useWindowScroll, useWindowSize } from "react-use";

import styles from "./ProgressBar.module.scss";

export interface IProgressBarProps {}

const ProgressBar: FC<IProgressBarProps> = (props) => {
  // Compare the full length in px of the document to the viewport height which represents the maximum scroll in px
  const { height: windowHeight, width: windowWidth } = useWindowSize();
  const { y: scrollY } = useWindowScroll();
  const [progress, setProgress] = useState<number>(0);
  useEffect(() => {
    const body = document.body,
      html = document.documentElement;

    const documentHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    const scrollMax = documentHeight - windowHeight;
    setProgress((scrollY / scrollMax) * 100);
  }, [windowHeight, windowWidth, scrollY]);

  return (
    <div className={styles.progressBarContainer}>
      <div
        className={styles.progressBar}
        style={{
          width: `${progress}%`,
        }}
      />
    </div>
  );
};

ProgressBar.defaultProps = {};

export default ProgressBar;
