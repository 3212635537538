import React, { FC } from "react";
import { useNavigationContext } from "../../context/NavigationContext";
import { MenuDetails } from "../../models/api/menu.model";
import ApiSectionDownload from "../../models/api/sections/sectionDownload.model";
import SectionDownload from "./SectionDownload";
import styles from "./SectionDownloadReport.module.scss";
import { APIConfig } from "../../config/api";
import { getPdfUrlFromSlug } from "../../utils/pdf.utils";

export interface ISectionDownloadReportProps {}

const SectionDownloadReport: FC<ISectionDownloadReportProps> = (props) => {
  const { menu, currentMenuIndex, slug } = useNavigationContext();

  // TODO could be great to avoi mockup the CMS model
  const REPORT_DATA: ApiSectionDownload = {
    __component: "section.download",
    title: "Téléchargements",
    items: [],
  };
  if (REPORT_DATA.items) {
    slug &&
      slug !== "full" &&
      REPORT_DATA.items.push({
        id: 1,
        description: `Télécharger la page "${menu[currentMenuIndex]?.title}" du rapport d'activités, au format PDF`,
        file: {
          data: {
            id: 1,
            attributes: {
              name: "",
              alternativeText: "",
              caption: "",
              hash: "",
              ext: ".pdf",
              mime: "application/pdf",
              size: 0,
              url: getPdfUrlFromSlug(slug),
              provider: "",
              provider_metadata: null,
              createdAt: "",
              updatedAt: "",
              previewUrl: "",
              width: 0,
              height: 0,
            },
          },
        },
        buttonLabel: "Télécharger le fichier",
      });
    REPORT_DATA.items.push({
      id: 2,
      description: "Télécharger le rapport complet, au format PDF",
      file: {
        data: {
          id: 1,
          attributes: {
            name: "",
            alternativeText: "",
            caption: "",
            hash: "",
            ext: ".pdf",
            mime: "application/pdf",
            size: 0,
            url: getPdfUrlFromSlug("full"),
            provider: "",
            provider_metadata: null,
            createdAt: "",
            updatedAt: "",
            previewUrl: "",
            width: 0,
            height: 0,
          },
        },
      },
      buttonLabel: "Télécharger le fichier",
    });
  }
  return (
    <div className={styles.sectionWrap}>
      <SectionDownload {...REPORT_DATA} />
    </div>
  );
};

SectionDownloadReport.defaultProps = {};

export default SectionDownloadReport;
