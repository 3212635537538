import Image from "next/image";
import React, { FC } from "react";
import classNames from "classnames";
import Link from "next/link";

import styles from "./MenuTop.module.scss";
import Socials from "./Socials";
import Typo from "./Typo";

export interface IMenuTopProps {
  isMenuOpen: boolean;
  toggleMenu: () => void;
}

const MenuTop: FC<IMenuTopProps> = (props) => {
  return (
    <div className={styles.container}>
      <div className={styles.subContainer}>
        <Link href="/" scroll={false} className={styles.logo}>
          <Image
            src={"/vector/logo-white.svg"}
            alt="Logo e-santé occitanie"
            width={187}
            height={60}
            layout="fixed"
          />
        </Link>
        <div className={styles.separator}></div>
        <Typo variant="headline" color="light" className={styles.title}>
          Rapport d&apos;activités 2023 du GRADeS e-santé Occitanie
        </Typo>
      </div>
      <div className={styles.subContainer}>
        <div className={styles.socials}>
          <Socials />
        </div>
        <button
          className={styles.menuButton}
          onClick={() => props.toggleMenu()}
        >
          <Typo variant="button" color="light">
            {props.isMenuOpen ? "Fermer" : "Menu"}
          </Typo>
          <div className={styles.burger}>
            <div
              className={classNames(
                styles.burgerDiv,
                styles.burgerFirst,
                props.isMenuOpen && styles.burgerFirst_active
              )}
            ></div>
            <div
              className={classNames(
                styles.burgerDiv,
                styles.burgerSecond,
                props.isMenuOpen && styles.burgerSecond_hidden
              )}
            ></div>
            <div
              className={classNames(
                styles.burgerDiv,
                styles.burgerThird,
                props.isMenuOpen && styles.burgerThird_active
              )}
            ></div>
          </div>
        </button>
      </div>
    </div>
  );
};

MenuTop.defaultProps = {};

export default MenuTop;
