import classNames from "classnames";
import fileSize from "filesize";
import React, { FC } from "react";
import { HiOutlineDocumentDownload } from "react-icons/hi";
import ApiDownload from "../models/api/utils/dowload.model";
import Button from "./Button";
import styles from "./Download.module.scss";
import Typo from "./Typo";

export interface IDownloadProps extends ApiDownload {
  className: string;
}

const Download: FC<IDownloadProps> = (props) => {
  return (
    <div className={classNames(styles.container, props.className)}>
      <Typo variant="h4" className={styles.title}>
        {props.description}
      </Typo>
      {props.file.data?.attributes?.size ? (
        <Typo className={styles.size}>
          Poids du fichier :{" "}
          {fileSize(props.file.data?.attributes?.size * 1000)}
        </Typo>
      ) : (
        ""
      )}
      <Button
        href={props.file.data?.attributes?.url}
        text={props.buttonLabel}
        icon={HiOutlineDocumentDownload}
        className={styles.button}
        isDownload
      />
    </div>
  );
};

Download.defaultProps = {};

export default Download;
