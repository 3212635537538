import classNames from "classnames";
import React, { FC, ReactNode, useEffect, useRef, useState } from "react";

import Container from "../Container";
import ImageCustom from "../ImageCustom";
import ScrollHandler from "../ScrollHandler";
import Typo from "../Typo";
import styles from "./SectionBase.module.scss";

export interface ISectionBaseProps {
  className?: string;
  background?: "primary" | "secondary";
  character?: "1" | "2" | "3" | "4";
  characterPosition?: "left" | "right";
  decorator?: "1" | "2" | "3";
  title?: string;
  titlePosition?: "left" | "center" | "padding-left";
  titleStyle?: "primary" | "secondary";
  titleClass?: string;
  children: ReactNode;
}

const SectionBase: FC<ISectionBaseProps> = (props) => {
  const sectionRef = useRef<HTMLDivElement>(null);

  /**
   * Handle display of the ScrollHandler, only on the first page section.
   * It could have been done with a React portal, but it's seems really hard
   * to get it working with SSR/SSG.
   */
  const [isFirstSection, setIsFirstSection] = useState<boolean>(false);
  useEffect(() => {
    if (document.querySelector(".section") === sectionRef.current) {
      setIsFirstSection(true);
    }
  }, []);
  return (
    <div
      className={classNames(
        styles.container,
        styles[`container_background-${props.background}`],
        props.title && styles.container_withTitle,
        props.decorator === "1" && styles.container_long,
        props.className,
        "section" // used by ScrollHandler to scroll to the next section
      )}
      ref={sectionRef}
    >
      {props.title && (
        <Container>
          <Typo
            variant="h1"
            component="h2"
            color={props.background === "primary" ? "primary" : "light"}
            className={classNames(
              styles.title,
              styles[`title_${props.titleStyle}`],
              styles[`title_position-${props.titlePosition}`],
              props.titleClass
            )}
          >
            {props.title}
          </Typo>
        </Container>
      )}
      {props.children}
      {props.character && (
        <div
          className={classNames(
            styles.character,
            styles[`character_position-${props.characterPosition}`]
          )}
        >
          <ImageCustom
            src={`/vector/characters/${props.character}.svg`}
            alt=""
            width={135}
            height={345}
            objectPosition="bottom"
          />
        </div>
      )}
      {props.decorator && (
        <div
          className={classNames(
            styles.decorator,
            styles[`decorator_type-${props.decorator}`]
          )}
        >
          <ImageCustom
            src={`/vector/decorators/${props.decorator}.svg`}
            alt=""
            layout="fill"
          />
        </div>
      )}
      {isFirstSection && <ScrollHandler />}
    </div>
  );
};

SectionBase.defaultProps = {
  background: "primary",
  characterPosition: "left",
  titlePosition: "left",
  titleStyle: "primary",
};

export default SectionBase;
