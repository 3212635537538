import {
  createContext,
  createRef,
  FunctionComponent,
  ReactNode,
  RefObject,
  useContext,
  useEffect,
  useState,
} from "react";
import { useWindowScroll, useWindowSize } from "react-use";
import { MenuDetails } from "../models/api/menu.model";
import { ApiSection } from "../models/api/section.model";
import { Chapter } from "../models/chapter.model";
import { slugify } from "../utils/string.utils";

export interface INavigationContext {
  chapters: Chapter[];
  refsChapters: RefObject<HTMLDivElement>[];
  chaptersReached: number[];
  slug?: string;
  menu: MenuDetails[];
  currentMenuIndex: number;
}

const defaultNavigationContext = {
  chapters: [],
  refsChapters: [],
  chaptersReached: [],
  menu: [],
  currentMenuIndex: 0,
};

const NavigationContext = createContext<INavigationContext>(
  defaultNavigationContext
);

export const useNavigationContext = () => useContext(NavigationContext);

export interface INavigationContextProviderApiProps {
  slug?: string;
  menu: MenuDetails[];
}
export interface INavigationProviderProps
  extends INavigationContextProviderApiProps {
  sections: ApiSection[];
  children: ReactNode;
}

const NavigationProvider: FunctionComponent<INavigationProviderProps> = (
  props
) => {
  const chapters: Chapter[] = props.sections.map((section, index) => {
    const title =
      section.__component !== "section.spacer" ? section.title ?? "" : "";
    const id = slugify(title);
    return {
      title: title,
      id: id,
    };
  });

  const refsChapters: RefObject<HTMLImageElement>[] = Array(
    props.sections.length
  )
    .fill(0)
    .map(() => createRef());

  const [chaptersReached, setChaptersReached] = useState<number[]>([]);

  const { y: windowScrollY } = useWindowScroll();
  const { height: windowHeight } = useWindowSize();

  useEffect(() => {
    const newChaptersReached: number[] = [];
    refsChapters.forEach((refChapter, index) => {
      if (!refChapter.current) return;
      if (refChapter.current.offsetTop < windowScrollY + windowHeight / 4) {
        newChaptersReached.push(index);
      }
    });
    setChaptersReached(newChaptersReached);
    // TODO remove disable if possible
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowScrollY, windowHeight]);

  const currentMenuIndex = props.menu.findIndex(
    (menuItem) => menuItem.slug === props.slug
  );

  return (
    <NavigationContext.Provider
      value={{
        chapters,
        refsChapters,
        chaptersReached,
        slug: props.slug,
        menu: props.menu,
        currentMenuIndex,
      }}
    >
      {props.children}
    </NavigationContext.Provider>
  );
};

export default NavigationProvider;
