import React, { FC, ReactNode, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useRouter } from "next/router";

import { MenuDetails } from "../models/api/menu.model";
import styles from "././PageLayout.module.scss";
import Footer from "./Footer";
import MenuTop from "./MenuTop";
import NavigationPanel from "./NavigationPanel";
import ProgressBar from "./ProgressBar";
import SectionDownloadReport from "./sections/SectionDownloadReport";
import NavigationBottom from "./NavigationBottom";
import { useNavigationContext } from "../context/NavigationContext";

export interface IPageLayoutProps {
  menu: MenuDetails[];
  slug?: string;
  children?: ReactNode;
}

const PageLayout: FC<IPageLayoutProps> = (props) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const router = useRouter();
  const { currentMenuIndex } = useNavigationContext();

  return (
    <>
      <MenuTop
        isMenuOpen={isMenuOpen}
        toggleMenu={() => setIsMenuOpen(!isMenuOpen)}
      />
      <ProgressBar />
      <NavigationPanel isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />

      <AnimatePresence
        exitBeforeEnter
        onExitComplete={() => window.scrollTo(0, 0)}
      >
        <motion.main
          key={router.asPath}
          initial={{ opacity: 0, x: -100 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 100 }}
          transition={{ ease: "easeOut", duration: 0.3 }}
          className={styles.mainContainer}
        >
          {props.children}
          {currentMenuIndex >= 0 && (
            <>
              <SectionDownloadReport />
              <NavigationBottom />
            </>
          )}
          <Footer />
        </motion.main>
      </AnimatePresence>
    </>
  );
};

PageLayout.defaultProps = {};

export default PageLayout;
