import React, { FC } from "react";
import classNames from "classnames";
import Link from "next/link";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import { BsFileEarmarkArrowDown } from "react-icons/bs";

import styles from "./CTA.module.scss";
import Typo from "./Typo";
import { APIConfig } from "../config/api";
import { getPdfUrlFromSlug } from "../utils/pdf.utils";

export interface ICTAProps {
  type?: "download-report" | "navigation";
  color?: "primary" | "secondary";
  href?: string;
  text?: string;
  reverse?: boolean;
  className?: string;
}

const CTA: FC<ICTAProps> = (props) => {
  let Icon =
    props.type === "download-report" ? BsFileEarmarkArrowDown : FaArrowRight;
  if (props.reverse && props.type !== "download-report") {
    Icon = FaArrowLeft;
  }

  const href =
    props.type === "download-report"
      ? getPdfUrlFromSlug("full")
      : props.href ?? "";

  const renderCTA = () => (
    <div
      className={classNames(
        styles.container,
        styles[`container_${props.color}`],
        props.reverse && styles.container_reverse,
        props.className
      )}
    >
      <div
        className={classNames(
          styles.contentWrapper,
          props.reverse && styles.contentWrapper_reverse,
          styles.text
        )}
      >
        <Typo variant="cta" color="light">
          {props.text}
        </Typo>
      </div>
      <div
        className={classNames(
          styles.iconWrapper,
          styles[`iconWrapper_${props.color}`]
        )}
      >
        <Icon
          className={classNames(
            styles.icon,
            styles[`icon_${props.color}`],
            styles.icon_only
          )}
        />
      </div>
    </div>
  );
  return props.type === "download-report" ? (
    <a
      href={href}
      className={classNames(styles.link, styles[`link_${props.color}`])}
      rel="noopener noreferrer"
      target="_blank"
    >
      {renderCTA()}
    </a>
  ) : (
    <Link
      href={href}
      scroll={false}
      className={classNames(styles.link, styles[`link_${props.color}`])}
    >
      {renderCTA()}
    </Link>
  );
};

CTA.defaultProps = {
  type: "download-report",
  reverse: false,
};

export default CTA;
