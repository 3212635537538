import React, { FC } from "react";
import classNames from "classnames";

import styles from "./Socials.module.scss";
import Button from "./Button";
import Typo from "./Typo";
import socialNetworks from "../config/socialNetworks";

export interface ISocialsProps {
  className?: string;
}

const Socials: FC<ISocialsProps> = (props) => {
  return (
    <div className={classNames(styles.container, props.className)}>
      <Typo variant="socials" color="light">
        Suivez nous :
      </Typo>
      <div className={styles.wrapper}>
        {Object.keys(socialNetworks).map((item) => (
          <Button
            key={socialNetworks[item].name}
            className={classNames(styles.button)}
            icon={socialNetworks[item].picto}
            color="light"
            href={socialNetworks[item].link}
            isExternal
            ariaLabel={socialNetworks[item].name}
          />
        ))}
      </div>
    </div>
  );
};

Socials.defaultProps = {};

export default Socials;
