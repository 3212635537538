import React, { FC } from "react";
import { FiArrowDown } from "react-icons/fi";

import styles from "./ScrollHandler.module.scss";
import Typo from "./Typo";

export interface IScrollHandlerProps {}

const ScrollHandler: FC<IScrollHandlerProps> = (props) => {
  function handleClick() {
    if (!document) {
      return;
    }
    const sectionElements = document.querySelectorAll(".section");
    if (sectionElements[1]) {
      sectionElements[1].scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }

  return (
    <button className={styles.button} onClick={() => handleClick()}>
      <Typo variant={"button"} className={styles.label}>
        Scroll
      </Typo>
      <FiArrowDown className={styles.icon} />
    </button>
  );
};

ScrollHandler.defaultProps = {};

export default ScrollHandler;
