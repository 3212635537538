import React, { FC } from "react";
import Link from "next/link";
import { IconType } from "react-icons";
import { FiExternalLink } from "react-icons/fi";
import classNames from "classnames";

import styles from "./Button.module.scss";
import Typo from "./Typo";

export interface IButtonProps {
  className?: string;
  color?:
    | "primary"
    | "secondary"
    | "variant-1"
    | "variant-1-dark"
    | "variant-2"
    | "variant-3"
    | "light";
  icon?: IconType;
  text?: string;
  href?: string;
  isExternal?: boolean;
  ariaLabel?: string;
  isDownload?: boolean;
}

const Button: FC<IButtonProps> = (props) => {
  const Icon = props.icon ?? (props.isExternal ? FiExternalLink : undefined);

  function renderButtonContent() {
    return (
      <>
        {props.text && (
          <Typo
            variant="button"
            color="light"
            className={classNames(styles.label, styles[`label_${props.color}`])}
          >
            {props.text}
          </Typo>
        )}
        {Icon &&
          (props.text ? (
            <div className={styles.iconContainer}>
              <Icon
                className={classNames(
                  styles.icon,
                  styles[`icon_${props.color}`]
                )}
              />
            </div>
          ) : (
            <Icon
              className={classNames(
                styles.icon,
                styles[`icon_${props.color}`],
                styles.icon_only
              )}
            />
          ))}
      </>
    );
  }

  function getClassNames() {
    return classNames(
      styles.button,
      styles[`color_${props.color}`],
      Icon && props.text
        ? styles.button_withTextAndIcon
        : Icon
        ? styles.button_withIcon
        : styles.button_withText,
      props.className
    );
  }

  return props.href ? (
    props.isExternal ? (
      <a
        href={props.href}
        target="_blank"
        rel="noopener noreferrer"
        className={getClassNames()}
        aria-label={props.ariaLabel}
      >
        {renderButtonContent()}
      </a>
    ) : (
      <Link
        href={props.href}
        scroll={false}
        aria-label={props.ariaLabel}
        target={props.isDownload ? "_blank" : "_self"}
        rel={props.isDownload ? "noopener noreferrer" : ""}
        className={getClassNames()}
      >
        {renderButtonContent()}
      </Link>
    )
  ) : (
    <button className={getClassNames()} aria-label={props.ariaLabel}>
      {renderButtonContent()}
    </button>
  );
};

Button.defaultProps = {
  color: "primary",
  isExternal: false,
};

export default Button;
